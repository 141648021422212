import _ from 'lodash';
import type { IMetricDefinition } from '../lib/types';
import { DOMParser, purifyURL } from '../lib/dom/html';
import Utils from '../lib/utils';

interface IGridItemCellRenderImage {
    klass: string;
    value: string | null;
}

interface IGridItemCellRenderGroupBy {
    klass: string;
    value: string;
}

function getMetricValueClass(metric: IMetricDefinition, value: unknown): string | null {
    if (typeof value !== 'number') return null;
    if (!metric.cellClass?.includes('percent')) return null;
    const valueNum = metric.cellClass === 'percent-inverted' ? value * -1 : value;
    if (valueNum > 0) return 'percent-positive';
    if (valueNum < 0) return 'percent-negative';
    return null;
}

function getMetricElement(
    $filter: angular.IFilterService,
    params: { metric: IMetricDefinition; value: unknown; isBare: boolean },
) {
    const { metric } = params;

    let valueStr = (() => {
        const value = params.value;
        if (typeof value === 'number') return value.toString();
        if (typeof value !== 'string') return null;
        if (value.length > 0) return value;
        return null;
    })();

    if (metric.cellFilter && typeof valueStr === 'string') {
        const [filterName, ...filterArgs] = metric.cellFilter.split(':');
        try {
            valueStr = filterName ? $filter(filterName)(valueStr, ...filterArgs) : valueStr;
        } catch (err) {
            console.warn('[Grid Page] - Unsupported filter', filterName, err);
        }
    }

    let headerNameEl: null | HTMLElement = null;
    const headerName = params.isBare && metric.headerName === 'TY' ? null : metric.headerName;
    if (headerName) {
        headerNameEl = document.createElement('div');
        headerNameEl.classList.add('item-metric-header-name');
        headerNameEl.textContent = headerName;
    }

    const valueEl = (value => {
        const el = document.createElement('div');
        el.classList.add('item-metric-value');
        if (value === null) {
            el.classList.add('blank');
        } else {
            const valueClass = getMetricValueClass(metric, params.value);
            if (valueClass) el.classList.add(valueClass);
            el.textContent = value;
        }
        return el;
    })(valueStr);

    const container = document.createElement('div');
    container.classList.add('item-metric');
    if (headerNameEl) container.appendChild(headerNameEl);
    container.appendChild(valueEl);
    return container;
}

function getMetricElements(
    $filter: angular.IFilterService,
    metrics: IMetricDefinition[],
    getter: (data: IMetricDefinition) => unknown,
): HTMLElement[] {
    if (metrics.length < 1) return [];

    const metricsByHeaderGroup = _.groupBy(metrics, x => x.headerGroup);
    const orderedHeaderGroups = metrics.reduce<string[]>((acc, metric) => {
        if (acc.includes(metric.headerGroup)) return acc;
        return [...acc, metric.headerGroup];
    }, []);

    const result: HTMLElement[] = [];
    for (const headerGroup of orderedHeaderGroups) {
        const metrics = metricsByHeaderGroup[headerGroup] ?? [];
        const isBare = metrics.length === 1 && metrics[0]?.headerName === 'TY';

        const itemMetricGroupElement = document.createElement('div');
        itemMetricGroupElement.classList.add('item-metric-group', ...(isBare ? ['bare'] : []));

        const itemMetricHeaderGroupEl = document.createElement('div');
        itemMetricHeaderGroupEl.classList.add('item-metric-header-group');
        const itemMetricHeaderGroupValueEl = document.createElement('span');
        itemMetricHeaderGroupValueEl.textContent = headerGroup;
        itemMetricHeaderGroupEl.appendChild(itemMetricHeaderGroupValueEl);
        itemMetricGroupElement.appendChild(itemMetricHeaderGroupEl);

        const itemMetricHeaderNames = document.createElement('div');
        itemMetricHeaderNames.classList.add('item-metric-values');
        itemMetricGroupElement.append(itemMetricHeaderNames);

        for (const metric of metrics) {
            const value = getter(metric);
            const element = getMetricElement($filter, { metric, value, isBare });
            itemMetricHeaderNames.append(element);
        }
        result.push(itemMetricGroupElement);
    }
    return result;
}

const getItemExtraPropertiesConfig = (
    selectedExtraProperties: { id: string; column?: string }[],
    item: undefined | Record<string, unknown>,
) => {
    return selectedExtraProperties.map(descriptor => {
        const column = descriptor.column ?? descriptor.id.replace(/^.*?\./, '');
        const columnKey = `item_${column}`;
        const klass = ['item-property', `item-property-${column.replace(/_/g, '-')}`];
        const value = String(item?.[columnKey] ?? '');
        return { klass, value };
    });
};

const createOrUpdateItemMetricsElement = (metrics: HTMLElement[], container?: undefined | HTMLElement): HTMLElement => {
    container = container ?? document.createElement('div');
    container.classList.add('item-metrics');
    const isEmpty = metrics.length === 0;
    isEmpty ? container.classList.add('hide') : container.classList.remove('hide');
    container.replaceChildren(...metrics);
    return container;
};

export const getMetricsElement = (
    $filter: angular.IFilterService,
    metrics: IMetricDefinition[],
    item: undefined | Record<string, unknown>,
) => {
    return getMetricElements($filter, metrics, (metric: IMetricDefinition) => item?.[metric.field]);
};

export interface ItemCellRendererInitParams {
    item?: Record<string, unknown> | undefined;
    label?: string | number | undefined;
    imagesEnabled?: boolean;
    itemsThemeLarge?: undefined | boolean;
    availableMetrics?: IMetricDefinition[] | Record<string, IMetricDefinition> | undefined;
    selectedMetrics?: string[] | undefined;
    selectedExtraProperties?: { column?: string; id: string }[] | undefined;
}

export class ItemCellRenderer {
    protected klass: string;
    eGuiElement: HTMLDivElement | undefined;
    eGuiImageElement: HTMLElement | undefined;
    eGuiMetricsElement: HTMLElement | undefined;
    eGuiPropertiesElement: HTMLElement | undefined;

    protected readonly $filter: angular.IFilterService;
    protected item: Record<string, unknown> | undefined;
    protected metrics: IMetricDefinition[] = [];
    protected availableMetrics: Record<string, IMetricDefinition> = {};
    protected imageUrl: string | undefined | null;
    protected itemsThemeLarge = false;
    protected itemExtraPropertiesConfig: { klass: string[]; value: string }[] = [];
    protected metricsHTML: HTMLElement[] = [];
    protected imagesEnabled = true;

    constructor($filter: angular.IFilterService) {
        this.$filter = $filter;
        this.klass = 'item-grid-cell-value';
    }

    protected createOrUpdateImageElement(enabled: boolean, url: undefined | null | string, element?: HTMLElement) {
        element = element ?? document.createElement('div');
        element.classList.add('item-image');
        if (!enabled) {
            element.classList.add('hide');
        } else {
            element.classList.remove('hide');
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        let image = element.childNodes[0] as undefined | HTMLImageElement;
        const isUpdate = image !== undefined;
        image ??= document.createElement('img');
        url === null ? image.classList.add('blank') : image.classList.remove('blank');
        if (!isUpdate && typeof url === 'string') {
            image.setAttribute('src', url);
            image.setAttribute('onload', "this.classList.add('loaded')");
            image.setAttribute('onerror', "this.classList.add('blank'); this.removeAttribute('src')");
        }
        element.replaceChildren(image);
        return element;
    }

    protected createOrUpdateItemMetricsElement(
        metrics: IMetricDefinition[],
        item: undefined | Record<string, unknown>,
        container?: undefined | HTMLElement,
    ): HTMLElement {
        const elements = getMetricsElement(this.$filter, metrics, item);
        return createOrUpdateItemMetricsElement(elements, container);
    }

    protected createPropertiesElement(property: string | number | null | undefined) {
        const el = document.createElement('div');
        el.classList.add('item-properties');
        const propertyEl = document.createElement('span');
        propertyEl.classList.add('item-property');
        propertyEl.textContent = property?.toString() ?? '';
        el.replaceChildren(
            propertyEl,
            ...this.itemExtraPropertiesConfig.flatMap(({ klass, value }) => {
                if (!value) return [];
                const span = document.createElement('span');
                span.classList.add(...klass);
                span.textContent = value ?? '';
                return span;
            }),
        );
        return el;
    }

    protected getImageUrl(item: undefined | Record<string, unknown>): undefined | null | string {
        if (!item) return undefined;
        return typeof item.item_image === 'string' ? purifyURL(item.item_image) : null;
    }

    protected resetElements() {
        this.eGuiElement = undefined;
        this.eGuiImageElement = undefined;
        this.eGuiMetricsElement = undefined;
        this.eGuiPropertiesElement = undefined;
    }

    init(params: ItemCellRendererInitParams) {
        this.resetElements();

        const {
            item,
            label,
            availableMetrics,
            imagesEnabled = true,
            itemsThemeLarge = false,
            selectedMetrics = [],
            selectedExtraProperties = [],
        } = params;

        this.itemsThemeLarge = itemsThemeLarge;
        this.imagesEnabled = imagesEnabled;
        this.item = item;
        this.availableMetrics = Array.isArray(availableMetrics)
            ? _.keyBy(availableMetrics, 'field')
            : availableMetrics ?? {};
        this.metrics = Utils.Object.pickValues(this.availableMetrics, selectedMetrics);
        this.itemExtraPropertiesConfig = getItemExtraPropertiesConfig(selectedExtraProperties, item);
        this.imageUrl = this.getImageUrl(item);

        // Build HTML
        const eGuiElement = document.createElement('div');
        ['item-grid-cell', this.klass, itemsThemeLarge ? 'item-cell-large' : ''].forEach(klass => {
            if (klass && !_.isNil(klass) && klass !== '') eGuiElement.classList.add(klass);
        });

        if (!this.item && this.klass === 'item-grid-cell-value') {
            eGuiElement.classList.add('empty');
        } else {
            eGuiElement.classList.remove('empty');
        }

        // Add IMAGE
        this.eGuiImageElement = this.createOrUpdateImageElement(
            this.imagesEnabled && this.item !== undefined,
            this.imageUrl,
            this.eGuiImageElement,
        );
        eGuiElement.appendChild(this.eGuiImageElement);

        const itemInfoEl = document.createElement('div');
        itemInfoEl.classList.add('item-info');

        const itemPropertiesEl = this.createPropertiesElement(label);
        itemInfoEl.appendChild(itemPropertiesEl);

        this.setMetrics(this.metrics);
        itemInfoEl.appendChild(this.eGuiMetricsElement!);

        eGuiElement.appendChild(itemInfoEl);
        this.eGuiElement = eGuiElement;
    }

    public setImagesEnabled(imagesEnabled: boolean): void {
        this.imagesEnabled = imagesEnabled;
        this.eGuiImageElement = this.createOrUpdateImageElement(
            this.imagesEnabled && this.item !== undefined,
            this.imageUrl,
            this.eGuiImageElement,
        );
    }

    public setMetrics(metrics: (string | IMetricDefinition)[] = []): void {
        const fields = metrics.map(metric => (typeof metric === 'string' ? metric : metric.field));
        this.metrics = Utils.Object.pickValues(this.availableMetrics, fields);
        this.eGuiMetricsElement = this.createOrUpdateItemMetricsElement(
            this.metrics,
            this.item,
            this.eGuiMetricsElement,
        );
    }

    public getGui() {
        return this.eGuiElement ?? document.createElement('div');
    }
}

export interface InfoItemCellRendererInitParams {
    item?: Record<string, string | number | undefined>;
    label?: string | number | undefined;
    availableMetrics: Record<string, IMetricDefinition> | IMetricDefinition[];
    selectedMetrics: string[];
}

export class InfoItemCellRenderer extends ItemCellRenderer {
    constructor($filter: angular.IFilterService) {
        super($filter);
        this.klass = 'item-grid-cell-total';
    }
}

function cloneElement<T extends Node>(element: T): T {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return element.cloneNode(true) as T;
}
